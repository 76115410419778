// Element.closest()
if (!Element.prototype.closest) {
	
	Element.prototype.closest = function(css) {
		
		let node = this,
			result,
			matches = node.matches || node.matchesSelector || node.webkitMatchesSelector || node.mozMatchesSelector || node.msMatchesSelector;	
		

		// polyfill matches					
		if (!matches) {
		
			(node.matches = node.matchesSelector = function matches(selector) {
		        
		        var matches = document.querySelectorAll(selector),
		        	th = this;
		        
		        return Array.prototype.some.call(matches, function(e) {
		        
		            return e === th;
		        
		        });
		    
		    })
		
		} else {
			
			node.matches = node.matchesSelector = matches;
		
		}


		result = node.matches(css);


		while (node) {
		
			if (result){
				
				return node;	
			
			} else {

				node = node.parentElement;

			}	 
	   	
	   	} 
	   	
	   	return null; 
		
	}

}