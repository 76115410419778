// libs https://splidejs.com/
import './splide.min.js'


/**
 * Basic UI: Slider(Splide.js).
 */
const BasicSliderInitList = [];
const BasicSlider = (() => {

    class Core {

        constructor(element) {

            this.$el = element;
            this.$slides = Array.from(this.$el.querySelectorAll('.splide__slide'));            // slides.
            this.$type = (this.$el.getAttribute('data-slider-thumbs')) ? 'thumbs' : 'default'; // type: thumbs or default.                  

        }


        init() {

            this.variables();                                
            this.build();

        }


        variables() {
            
            let thumbsDataOptions = JSON.parse(this.$el.getAttribute('data-thumbs-options')) || {}; // thumbs parameters data-attr.
            let sliderDataOptions = JSON.parse(this.$el.getAttribute('data-slider-options')) || {}; // slider parameters data-attr.
            let sliderDataClasses = JSON.parse(this.$el.getAttribute('data-slider-classes'))   || {}; // clesses data-attr.            
            let sliderDataPadding = JSON.parse(this.$el.getAttribute('data-slider-padding'))   || {}; // paddings data-attr. 
            let sliderDataBreakpoints = JSON.parse(this.$el.getAttribute('data-slider-breakpoints')); // breakpoints data-attr.            

            /* 
                The following options are missing(values default):
                * dragAngleThreshold
                * swipeDistanceThreshold
                * flickVelocityThreshold
                * flickPower
                * flickMaxPages
                * accessibility
                * slideFocus
                * isNavigation
                * trimSpace
                * updateOnMove
                * throttle            
            */
            this.$sliderOptions = {
                /* 
                    type slider:
                        'slide' 
                            EN: A carousel with the slide transition 
                            RU: Карусель со слайд-переходом.                        
                        'loop'
                            EN: A carousel carousel
                            RU: Слайдер-карусель.                        
                        'fade'
                            EN: A carousel with the fade transition. This does not support the perPage option
                            RU: Карусель с плавным переходом. Не поддерживает параметр perPage.
                */    
                'type': (sliderDataOptions['type'] || 'slide'),
                
                /*
                 * EN: Determines whether to rewind the carousel or not. This does not work in the loop mode
                 * RU: Определяет, перематывать карусель назад или нет. Не работает в циклическом режиме.
                */
                'rewind': (sliderDataOptions['rewind'] || false),
                
                /*
                 * EN: The transition speed in milliseconds. If 0, the carousel immediately jumps to the target slide
                 * RU: Скорость перехода в миллисекундах. Если 0, карусель сразу переходит к целевому слайду.                 
                 */
                'speed': (sliderDataOptions['speed'] || 400),
                
                /*
                 * EN: The transition speed on rewind in milliseconds. The speed value is used as default
                 * RU: Скорость перехода при перемотке назад в миллисекундах. Значение скорости используется по умолчанию. 
                 */
                'rewindSpeed': (sliderDataOptions['rewindSpeed'] || 0),
                
                /*
                 * EN: Determines whether to disable any actions while the carousel is transitioning. Even if false, the carousel forcibly waits for the transition on the loop points
                 * RU: Определяет, отключать ли какие-либо действия во время перехода карусели. Даже если false, карусель принудительно ожидает перехода по точкам цикла. 
                 */
                'waitForTransition': (sliderDataOptions['waitForTransition'] !== undefined ? sliderDataOptions['waitForTransition'] : true),
                
                /*
                 * EN: Defines the carousel max width, accepting the CSS format such as 10em, 80vw
                 * RU: Определяет максимальную ширину карусели, принимая такой формат CSS, как 10em, 80vw.  
                 */
                'width': (sliderDataOptions['width'] || 0),
                
                /*
                 * EN: Defines the slide height, accepting the CSS format except for %
                 * RU: Определяет высоту слайда, принимая формат CSS, кроме %.
                 */
                'height': (sliderDataOptions['height'] || 0),
                
                /*
                 * EN: Fixes width of slides, accepting the CSS format. The carousel will ignore the perPage option if you provide this value
                 * RU: Фиксирует ширину слайдов, принимая формат CSS. Карусель будет игнорировать параметр perPage, если вы укажете это значение.
                 */
                'fixedWidth': (sliderDataOptions['fixedWidth'] || 0),
                
                /*
                 * EN: Fixes height of slides, accepting the CSS format except for %. The carousel will ignore perPage, height and heightRatio options if you provide this value
                 * RU: Фиксирует высоту слайдов, принимая формат CSS, кроме %. Карусель будет игнорировать параметры perPage, height и heightRatio, если вы укажете это значение.
                 */
                'fixedHeight': (sliderDataOptions['fixedHeight'] || 0),
                
                /*
                 * EN: Determines height of slides by the ratio to the carousel width. For example, when the carousel width is 1000 and the ratio is 0.3, the height will be 300. Be aware that Splide ignores this option when height or fixedHeight is provided
                 * RU: Определяет высоту слайдов по отношению к ширине карусели. Например, если ширина карусели равна 1000, а коэффициент равен 0,3, высота будет равна 300. Имейте в виду, что Splide игнорирует этот параметр, если указана высота или fixedHeight. 
                 */
                'heightRatio': (sliderDataOptions['heightRatio'] || 0),
                
                /*
                 * EN: If true, the width of slides are determined by their width. Do not provide perPage and perMove options (or set them to 1)
                 * RU: Если true, ширина слайдов определяется их шириной. Не указывайте параметры perPage и perMove (или установите для них значение 1).
                 */
                'autoWidth': (sliderDataOptions['autoWidth'] || false),
                
                /*
                 * EN: If true, the height of slides are determined by their height. Do not provide perPage and perMove options (or set them to 1)
                 * RU: Если true, высота слайдов определяется их высотой. Не указывайте параметры perPage и perMove (или установите для них значение 1).
                 */
                'autoHeight': (sliderDataOptions['autoHeight'] || false),
                
                /*
                 * EN: Determines the number of slides to display in a page. The value must be an integer
                 * RU: Определяет количество слайдов, отображаемых на странице. Значение должно быть целым числом.
                 */
                'perPage': (sliderDataOptions['perPage'] || 1),
                
                /*
                 * EN: Determines the number of slides to move at once. The following example displays 3 slides per page but moves slides one by one. The value must be an integer
                 * RU: Определяет количество слайдов, которые нужно перемещать одновременно. В следующем примере отображаются 3 слайда на странице, но слайды перемещаются по одному. Значение должно быть целым числом.
                 */
                'perMove': (sliderDataOptions['perMove'] || 1),
                
                /*
                 * EN: Specifies the number of clones on each side of the loop carousel
                 * RU: Указывает количество клонов на каждой стороне карусели цикла.
                 */
                'сlones': (sliderDataOptions['сlones'] || 0),
                
                /*
                 * EN: Defines the start index
                 * RU: Определяет начальный индекс.
                 */
                'start': (sliderDataOptions['start'] || 0),
                   
                /*
                 * EN: Determines which slide should be active if the carousel has multiple slides in a page
                 * RU: Определяет, какой слайд должен быть активным, если карусель содержит несколько слайдов на странице.  
                 */   
                'focus': (sliderDataOptions['focus'] || 0),
                
                /*
                 * EN: The gap between slides. The CSS format is acceptable, such as 1em
                 * RU: Промежуток между слайдами. Допустим формат CSS, например 1em.
                 */
                'gap': (sliderDataOptions['gap'] || 0),
                
                /*
                 * EN: The timing function for the CSS transition, such as linear, ease or cubic-bezier()
                 * RU: Функция синхронизации для перехода CSS, например linear, easy или cube-bezier().
                 */
                'easing': (sliderDataOptions['easing'] || 'cubic-bezier(.42,.65,.27,.99)'),
                
                /*
                 * EN: Determines whether to create arrows or not. This option still must be true if you provide arrows by HTML
                 * RU: Определяет, создавать стрелки или нет. Этот параметр по-прежнему должен быть истинным, если вы предоставляете стрелки с помощью HTML.
                 */
                'arrows': (sliderDataOptions['arrows'] !== undefined ? sliderDataOptions['arrows'] : true),
                
                /*
                 * EN: Changes the arrow SVG path, like 'm7.61 0.807-2.12...'. The SVG size must be 40×40
                 * RU: Изменяет путь стрелки SVG, например «m7.61 0.807-2.12...». Размер SVG должен быть 40×40.
                 */
                'arrowPath': (sliderDataOptions['arrowPath'] || 'M32.8738 18L35 20.1262L32.8738 22.2525L32.2349 21.6137L33.2707 20.578H5V19.6745H33.2707L32.2349 18.6388L32.8738 18Z'),
                
                /*
                 * EN: Determines whether to create pagination (indicator dots) or not  
                 * RU: Определяет, создавать ли нумерацию страниц (точки индикатора) или нет.
                 */   
                'pagination': (sliderDataOptions['pagination'] !== undefined ? sliderDataOptions['pagination'] : true),
                
                /*
                 * EN: Determines whether to enable autoplay or not. If 'paused', it will not begin when the carousel becomes active, which means you need to provide a play button or manually start it by Autoplay#play()
                 * RU: Определяет, включать автозапуск или нет. Если «пауза», она не начнется, когда карусель станет активной, что означает, что вам нужно предоставить кнопку воспроизведения или запустить ее вручную с помощью Autoplay#play().
                 */
                'autoplay': (sliderDataOptions['autoplay'] !== undefined ? sliderDataOptions['autoplay'] : false),
                
                /*
                 * EN: The autoplay interval duration in milliseconds
                 * RU: Продолжительность интервала автовоспроизведения в миллисекундах.
                 */
                'interval': (sliderDataOptions['interval'] || 5000),

                /*
                 * EN: Determines whether to pause autoplay on mouseover. This should be true for accessibility
                 * RU: Определяет, следует ли приостанавливать автовоспроизведение при наведении курсора мыши. Это должно быть верно для доступности.
                 */
                'pauseOnHover': (sliderDataOptions['pauseOnHover'] !== undefined ? sliderDataOptions['pauseOnHover'] : true),
                
                /*
                 * EN: Determines whether to pause autoplay while the carousel contains the active element (focused element). This should be true for accessibility
                 * RU: Определяет, следует ли приостанавливать автовоспроизведение, пока карусель содержит активный элемент (элемент в фокусе). Это должно быть верно для доступности.
                 */
                'pauseOnFocus': (sliderDataOptions['pauseOnFocus'] !== undefined ? sliderDataOptions['pauseOnFocus'] : true),
                
                /*
                 * EN: Determines whether to reset the autoplay progress when it is requested to start again
                 * RU: Определяет, сбрасывать ли прогресс автовоспроизведения, когда его запрашивают для повторного запуска.
                 */
                'resetProgress': (sliderDataOptions['resetProgress'] !== undefined ? sliderDataOptions['resetProgress'] : true),
                
                /*
                 * EN: To make lazy load work, the img element in the slide must have the data-splide-lazy attribute that indicates the path or URL to the source file
                 * RU: Чтобы отложенная загрузка работала, элемент img на слайде должен иметь атрибут data-splide-lazy, указывающий путь или URL-адрес исходного файла.
                    values:
                        * false — Disables lazy loading / Отключает ленивую загрузку;
                        * nearby — Starts loading only images around the active slide (page) / Начинает загружать только изображения вокруг активного слайда (страницы);
                        * sequentia — Loads images sequentially / Загружает изображения последовательно
                 */
                'lazyLoad': (sliderDataOptions['lazyLoad'] || 'nearby'),
                
                /*
                 * EN: Determines how many pages (not slides) around the active slide should be loaded beforehand. This only works when the lazyLoad option is 'nearby'
                 * RU: Определяет, сколько страниц (не слайдов) вокруг активного слайда должно быть загружено заранее. Это работает только тогда, когда опция lazyLoad находится рядом.
                 */
                'preloadPages': (sliderDataOptions['preloadPages'] || 1),
                
                /*
                 * EN: Determines whether to allow the user to drag the carousel or not
                 * RU: Определяет, разрешить ли пользователю перетаскивать карусель или нет.
                    values:
                        * true —  Enables drag / Включает перетаскивание;
                        * false — Disables drag / Отключает перетаскивание;
                        * 'free' — Enables the drag free mode / Включает режим без перетаскивания.
                 */
                'drag': (sliderDataOptions['drag'] !== undefined ? sliderDataOptions['drag'] : true),
                /*
                 * EN: The direction of the carousel
                 * RU: Направление карусели.
                    values:
                        * 'ltr' — Left to right / Слева направо;
                        * 'rtl' — Right to left / Справа налево;
                        * 'ttb' — Top to bottom / Сверху вниз.
                 */   
                'direction': (sliderDataOptions['direction'] || "ltr"),
                
                /*
                 * EN: Converts the image src to the css background-image URL of the parent element. This requires height, fixedHeight or heightRatio option.
                 * RU: Преобразует исходный код изображения в URL-адрес фонового изображения css родительского элемента. Для этого требуется опция height, fixedHeight или heightRatio.
                 */
                'cover': sliderDataOptions['core'] || false,

                /*
                 * EN: A set of adaptive options
                 * RU: Набор адаптивных опций.
                 */
                'breakpoints': sliderDataBreakpoints || false,

                /*
                 * EN: The collection of class names
                 * RU: Коллекция имен классов.
                 */
                'classes': {
                  
                    // arrows.
                    'arrows': `splide__arrows ${ sliderDataClasses['arrows'] || '' }`,
                    'arrow' : `splide__arrow  ${ sliderDataClasses['arrow'] || '' }`,
                    'prev'  : `splide__arrow--prev ${ sliderDataClasses['prev'] || '' }`,
                    'next'  : `splide__arrow--next ${ sliderDataClasses['next'] || '' }`,
                    
                    // pagination.
                    'pagination': `splide__pagination ${ sliderDataClasses['pagination'] || '' }`, // container
                    'page'      : `splide__pagination__page ${ sliderDataClasses['page'] || '' }`, // each button
                
                },

                /*
                 * EN: Sets the left/right padding for a horizontal carousel or top/bottom for a vertical carousel
                 * RU: Устанавливает отступ слева/справа для горизонтальной карусели или сверху/снизу для вертикальной карусели.
                 */
                'padding': {
                    'top':    sliderDataPadding['top'] || 0,
                    'left':   sliderDataPadding['left'] || 0,
                    'right':  sliderDataPadding['right'] || 0,
                    'bottom': sliderDataPadding['bottom'] || 0
                },
                
                /**
                 * EN: Determines whether to enable keyboard shortcuts or not. If enabled, you can control the slider by arrow keys.
                 * RU: Определяет, включать ли сочетания клавиш или нет. Если включено, вы можете управлять ползунком с помощью клавиш со стрелками.
                 */
                'keyboard': sliderDataOptions['keyboard'] || true,
            };


            // thumbs
            this.$thumbsOptions = {                
                pagination: false,
                rewind: true,                
                cover: true,
                isNavigation: true,
                
                // включить отложенную загрузку(по умолчанию 'nearby' — загружать img только у активного слайда)
                lazyLoad: 'nearby',                
                
                // эта опция работает, если в опции lazyLoad указано значение nearby
                preloadPages: 1,
                
                // фиксировання ширина слайдера(любая единица измерения в css)
                fixedWidth: (thumbsDataOptions['fixedWidth'] || 100),
                
                // масимальная высота слайдера(любая единица измерения в css)
                fixedHeight: (thumbsDataOptions['fixedHeight'] || 64),

                // индекс слайда для начала слайдера
                start: (thumbsDataOptions['start'] || 0),
                   
                // индекс слайда на котором следует сфокусироваться    
                focus: (thumbsDataOptions['focus'] || 0),

                // расстояние между слайдами
                gap: (thumbsDataOptions['gap'] || 10),
                
                // функция синхронизации анимации для перехода CSS
                easing: (thumbsDataOptions['easing'] || 'cubic-bezier(.42,.65,.27,.99)'),
                
                // стрелки(prev, next)
                arrows: (thumbsDataOptions['arrows'] !== undefined ? thumbsDataOptions['arrows'] : false),
                
                // путь svg стрелки(формат 'm7.61 0.807-2.12…', размер svg 40x40)
                arrowPath: (thumbsDataOptions['arrowPath'] || ''),                

                // адаптивный режим
                breakpoints: thumbsDataOptions['breakpoints'] || false,
            };

        }


        build() {
            
            // init
            if (this.$type == 'default') this.build_default();            
            if (this.$type == 'thumbs')  this.build_thumbs();
            
            this.$el.classList.add('slider');
            this.$el.classList.add('slider-init');

        }


        build_default() {

            this.$splide = new Splide(this.$el, this.$sliderOptions).mount();
            BasicSliderInitList.push(this.$splide);

        }


        build_thumbs() {

            this.create_thumbs(); // соберём разметку thumbs-слайдера.
            this.$sliderOptions['pagination'] = false; // для основного слайдера выключим пагинацию.
                    
            this.$splide = new Splide(this.$el, this.$sliderOptions),
            this.$thumbnails = new Splide(this.thumbsSlider, this.$thumbsOptions); 
            
            this.$splide.sync(this.$thumbnails).mount();
            this.$thumbnails.mount();

            BasicSliderInitList.push(this.$splide);

        }


        create_thumbs() {

            let imgElement = null,
                slideElement = null,                
                thumbsElement = null;


            // create main body
            thumbsElement = document.createElement('div');
            thumbsElement.classList.add('slider-thumbs');
            thumbsElement.classList.add('splide');
            thumbsElement.innerHTML = '<div class="splide__track"><div class="splide__list"></div></div>';

            // create slides
            this.$slides.forEach((value) => {

                slideElement = document.createElement('div');
                slideElement.classList.add('splide__slide');

                // pic
                if (value.getAttribute('data-slider-thumb') !== null) {

                    imgElement = document.createElement('img');
                    imgElement.setAttribute('src', value.getAttribute('data-slider-thumb'));                    
                    slideElement.innerHTML = imgElement.outerHTML;                     

                // no pic    
                } else {

                    slideElement.classList.add('splide__slide--no-pic')

                } 

                thumbsElement.querySelector('.splide__list').appendChild(slideElement);
                
            });


            // add the created thumbs-slider
            this.$el.insertAdjacentHTML('afterend', thumbsElement.outerHTML);
            this.thumbsSlider = this.$el.nextElementSibling;

        }


        // events
        on({event, listener}) {

            let splide = BasicSliderInitList.filter(element => element.root == this.$el) || false;

            if (splide !== false) splide[0].on(event, listener);
        
        }

    }


    let active = null;


	/**
	 * Slider initialization
	 * @param {object|string} variable - element for initializing slider 
	 * @param {object}        config   - element parameters 
	 */
    const init = (variable, config) => {

        let options =  config || {},
            element = null,
            elements = BasicCore.variables(variable, '.js-slider');

            
        try {

			if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
			if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
            

            elements.forEach((value) => {

                element = value;

                active = new Core(value, options);
                active.init();

            });

            
            return active;

        } catch(error) {

			console.error(`${BasicCore.logging['name']} Slider init. \nMessage: ${error.message} \nElement: `, element);

		}

    }


    /**
     * Slider events
     * @param {object|string} variable - slider for listening
     * @param {object}        config   - event parameters 
     */
    const on = (variable, config) => {

        let options =  config || {},
            element = null,
            elements = BasicCore.variables(variable, '.js-slider:not(.slider-init)');

        try {

            if (elements == false && variable !== undefined) throw new Error(BasicCore.logging['error']['missing']);
            if (elements == null  && variable !== undefined) throw new Error(BasicCore.logging['error']['type']);
            

            // run
			elements.forEach((value) => {

                element = value;
                active = new Core(value)
                active.on(options);

            });

            
            return active;

        } catch(error) {

            console.error(`${BasicCore.logging['name']} Slider on. \nMessage: ${error.message} \nElement: `, element);

        }

    };


    return { init, on };

})()


window.BasicSlider = BasicSlider;


export { BasicSlider };