// ** Basic UI **
import './modules/basic-ui/core';

// polyfills
import './modules/basic-ui/polyfills/find';
import './modules/basic-ui/polyfills/array.from';
import './modules/basic-ui/polyfills/closest';
import './modules/basic-ui/polyfills/number.parseInt';
import './modules/basic-ui/polyfills/startsWith';
import './modules/basic-ui/polyfills/customEvent';

// ui
// import { BasicAccordion } 			 from './modules/basic-ui/ui/accordions';
// import { BasicPopup } 				 from './modules/basic-ui/ui/popup';
// import { BasicReadmore } 			 from './modules/basic-ui/ui/readmore';
// import { BasicTabs } 				 from './modules/basic-ui/ui/tabs';
// import { BasicToggleVisibleContent } from './modules/basic-ui/ui/toggle-visible-content';
// import { BasicClassTransfer }		 from './modules/basic-ui/ui/сlass-transfer';
// import { BasicTooltip } 			 from './modules/basic-ui/ui/tooltip';
// import { BasicMask } 				 from './modules/basic-ui/ui/mask/mask';
import { BasicSlider } 				 from './modules/basic-ui/ui/slider/slider';
// import { BasicLazyLoadingImg } 		 from './modules/basic-ui/ui/lazy-loading-img';
// import { BasicDropdown } 		     from './modules/basic-ui/ui/dropdown';
// import { BasicSlideout } 		     from './modules/basic-ui/ui/slideout';

// // form controls
// import { BasicSelect }     from './modules/basic-ui/ui/form-controls/select/select';
// import { BasicCheckbox }   from './modules/basic-ui/ui/form-controls/checkbox';
// import { BasicDatepicker } from './modules/basic-ui/ui/form-controls/datepicker/datepicker';
// ** /Basic UI **


// init
document.addEventListener('DOMContentLoaded', function() {
	
	// BasicAccordion.init();
	// BasicPopup.init();
	// BasicReadmore.init();
	// BasicTabs.init();
	// BasicToggleVisibleContent.init();
	// BasicClassTransfer.init();
	// BasicTooltip.init();
	// BasicMask.init();
	BasicSlider.init();
	// BasicLazyLoadingImg.init();	
	// BasicDropdown.init();
	// BasicSlideout.init();


	// form-controls
	// BasicSelect.init();
	// BasicCheckbox.init();
	// BasicDatepicker.init();

});