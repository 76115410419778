if (!Array.prototype.find) {
    
    Object.defineProperty(Array.prototype, 'find', {
        
        value: function (predicate) {
            
            // 1. Let O be ? ToObject(this value).
            if (this == null) {

                throw new TypeError('"this" is null or not defined');

            }

            var o = Object(this);            
            var len = o.length >>> 0; // 2. Let len be ? ToLength(? Get(O, "length")).
            
            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                
                throw new TypeError('predicate must be a function');
            
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];            
            var k = 0; // 5. Let k be 0.

            // 6. Repeat, while k < len
            while (k < len) {

                var kValue = o[k];
                
                if (predicate.call(thisArg, kValue, k, o)) {
                
                    return kValue;
                
                }
                
                // e. Increase k by 1.
                k++;
            
            }

            // 7. Return undefined.
            return undefined;
        },

        configurable: true,
        writable: true

    });

}